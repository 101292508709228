// import "instantsearch.css/themes/algolia-min.css";

import { Box, Button, HStack } from '@chakra-ui/react';
import { TbCalendar, TbSquare, TbSquareCheckFilled } from 'react-icons/tb';
import { formatISO, parseISO } from 'date-fns';
import { useUser } from '@/hooks/auth';
import { useCallback, useMemo } from 'react';
import { MissionsListQueryParams } from './MissionsList';
import { useNavigate, useParams } from 'react-router-dom';
import { missionsRoute } from '@/constants/routes';
import QueryString from 'qs';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';


export interface MissionListFiltersProps {
  params?: MissionsListQueryParams
}

export interface FilterToggleButtonProps<T> {
  name: string
  label: string
  params?: T
  onChange: (newElement: Partial<T>) => void
}

function withoutProperty(obj, property) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [property]: _unused, ...rest } = obj

  return rest
}

export function FilterToggleButton<T>({ name, label, params, onChange }: FilterToggleButtonProps<T>) {

  const active = params && params[name]

  console.log({ params, name, active })

  const onClick = () => {
    if (active) {
      return onChange(withoutProperty(params, name))
    } else {
      return onChange({...params, [name]: true})
    }
  }

  return (
    <Button
      size={'sm'}
      onClick={onClick}
      leftIcon={active ? <TbSquareCheckFilled /> : <TbSquare />}
    >
      {label}
    </Button>
  )
}

export const MissionListFilters = ({ params }: MissionListFiltersProps) => {

  const { data: user } = useUser();
  const { orgId } = useParams()
  const navigate = useNavigate()

  const structureName = useMemo(() => user?.meta.user_structures?.find(e => e.structure_id.toString() === params?.structure_id)?.structure.short_name, [user, params])

  const onChange = useCallback((newParams) => {
    console.log("Onchange", { newParams, params })
    navigate(missionsRoute(orgId) + '?' + QueryString.stringify(newParams))
  }, [params, navigate, orgId])


  const rangeValues: DateRange | null = params?.active_between?.start_date && params?.active_between?.end_date ? [
    parseISO(params.active_between?.start_date),
    parseISO(params.active_between?.end_date)
  ] : null

  console.log({ params })

  const onActiveBetweenChange = (value: DateRange | null) => {
    if (!value && params?.active_between) {
      const { active_between, ...newParams} = params
      navigate(missionsRoute(orgId) + '?' + QueryString.stringify(newParams))
    } else if (value && value.length) {
      const newParams = {
        ...params,
        active_between: { start_date: formatISO(value[0]), end_date: formatISO(value[1]) }
      }
      navigate(missionsRoute(orgId) + '?' + QueryString.stringify(newParams))
    }
  }

  return (
    <Box overflowX={'auto'}>
      <HStack gap={3} px={3} pt={3} overflowX={'auto'} maxW={'100%'}>
        {/* <FilterToggleButton label="À venir" name='future' params={params} onChange={onChange} /> */}

        <DateRangePicker
          // max={form.values.active_between.end_date}
          aria-label="Date"
          value={rangeValues}
          onChange={onActiveBetweenChange}
          placeholder={"Filtrer par date"}
          caretAs={TbCalendar}
        />

      </HStack>
    </Box>
  );
}

