
import { deleteMission, getOrganizationMissions, getOrganizationMissionsKey } from '@/api/mission';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { MissionAttributes } from '@/interfaces/mission';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { MissionTable } from '@/screens/MissionTable/MissionTable';
import { useMemo, useState } from 'react';
import { parseMissionQueryParams } from '@/utils/missions';
import { VStack } from '@chakra-ui/react';
import { MissionListFilters } from './MissionListFilters';

export interface MissionsListQueryParams {
  structure_id?: RecordId
  user_id?: string
  mission_type?: string
  active?: boolean
  future?: boolean
  active_between?: {
    start_date?: string
    end_date?: string
  }
}


export const MissionsList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(() => parseMissionQueryParams(searchParams), [searchParams])

  const [{ pageIndex }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const [sorting, setSorting] =
    useState<SortingState>([{desc: true, id: 'begin_at'}])

  const queryKey = [getOrganizationMissionsKey, orgId, structureId, pageIndex, sorting, queryParams]
  const {
    data,
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationMissions(orgId, pageIndex + 1, {structure: [structureId], ...queryParams}, sorting) : null
  )

  const deleteMissionMutation = useOptimisticDeleteMutation<ApiResourceResponse<MissionAttributes>, RecordId>(queryClient, deleteMission, queryKey)

  const onDelete = async (missionId: RecordId) => {
    console.log("Delete !", { missionId })
    deleteMissionMutation.mutate(missionId)
  }

  return (
    <VStack spacing={3} alignContent={'flex-start'} alignItems={'stretch'} justifyContent={'stretch'}>
      <MissionListFilters params={queryParams} />
      {data?.data && <MissionTable
        onDelete={onDelete}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        data={data}
        sorting={sorting}
      />}
    </VStack>
  );
}
